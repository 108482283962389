.optionGroup{

    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

body{
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
    padding:20px;
}

button{
    color: black;
    padding: 14px 20px;
    margin: 8px 0;
    border: 1px solid black;
    border-radius: 10px;
}

.selected{
  background-color: lightgreen;
}
input[type="text"]{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid black;
    margin: 10px 0;
    width: 30vw;
}

.warn{
    color: darkred;
    font-size: 30px;
    background-color: lightcoral;
    padding: 10px;
    border-radius: 10px;
}

@media screen and (min-width: 800px) and (min-height: 600px){
    .calculationContainer{
        position: fixed;
        width: 40vw;
        max-height: 60vh;
        overflow-y: auto;
        right: 0;
        top: 0;
        padding: 10px;
        margin: 20px;
        border: 1px solid black;
        border-radius: 10px;
        background-color: aquamarine;
    }
}